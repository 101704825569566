/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    common: {
      init: function () {
        

        // hero slider
        $(".hero__slider-ver").owlCarousel({
          items: 1,
          autoplay: true,
          autoplayTimeout: 4000,
          // autoplaySpeed: 800,
          loop: true,
          dots: 0,
          margin: 100,
        });

        // product silders
        $(".product__slider").owlCarousel({
          items: 1,
          // autoplay: true,
          dotsSpeed: 800,
          autoplayTimeout: 3500,
          autoplaySpeed: 800,
          loop: true,
          dots: 0,
          navText: [
            '<img src="/wp-content/themes/vermeerchile/dist/images/branding/Arrow_right_Copy.svg" class="nosotros__arrow-right"/>',
            '<img src="/wp-content/themes/vermeerchile/dist/images/branding/Arrow_right.svg" class="nosotros__arrow-left"/>',
          ],
        });

        // productos submenus

        var togglers = document.querySelectorAll(".submenu__toggler");
        var submenus = document.querySelectorAll(".submenu");
        var arrows = document.querySelectorAll(".submenu__toggler-li");

        togglers.forEach(function (el, index) {
          el.addEventListener("click", function () {
            submenus[index].classList.toggle("toggle__submenu");
            arrows[index].classList.toggle("submenu__toggler-arrowDown");
          });
        });

        // productos filtro

        var inputFilter = document.querySelector(".input__filter");
        var filterDropdown = document.querySelector(".filter__dropdown");
        var productsRef = document.querySelectorAll("a[data-product]");

        filterDropdown.addEventListener("click", function (e) {
          e.stopPropagation();
        });

        inputFilter.addEventListener("keyup", function () {
          filterDropdown.innerHTML = "";
          var filterRegex = new RegExp(inputFilter.value, "gi");

          productsRef.forEach(function (el) {
            if (filterRegex.test(el.getAttribute("data-product"))) {
              var li = document.createElement("LI");
              var a = document.createElement("A");
              var span = document.createElement("SPAN");

              a.setAttribute("href", el.getAttribute("href"));
              a.setAttribute("target", "_blank");
              span.innerHTML = el.textContent;
              a.innerHTML = el.getAttribute("subcategory") + " - ";
              a.appendChild(span);
              li.appendChild(a);
              filterDropdown.appendChild(li);
            }
          });

          if (inputFilter.value.length !== 0) {
            filterDropdown.style.padding = "1% 2% 0% 3%";
          } else {
            filterDropdown.innerHTML = "";
            filterDropdown.style.padding = "0";
          }
        });


        var filterCleaner = document.querySelectorAll(".products__container");

        filterCleaner.forEach(function (element) {
          element.addEventListener("click", function () {
            filterDropdown.innerHTML = "";
            filterDropdown.style.padding = "0";
            filterDropdownEn.innerHTML = "";
            filterDropdownEn.style.padding = "0";
          });
        });

        // maps

        // mapa al inicio
        mapboxgl.accessToken =
          "pk.eyJ1IjoiZGF2aWRhcm1hbmRvIiwiYSI6ImNrY2Ixdnh1YzIxMTczNW9iOWpvM2liNzIifQ.PnNDoVNY-mHai_rYS7KiDQ";
        var map = new mapboxgl.Map({
          container: "map",
          style: "mapbox://styles/mapbox/streets-v11",
          center: [-70.637288, -33.541775],
          zoom: 10,
        });

        var marker = new mapboxgl.Marker()
          .setLngLat([-70.637288, -33.541775])
          .addTo(map);
        
        
      },

      finalize: function () {
        // JavaScript to be fired on all pages, after page specific JS is fired
      },
    },
    // Home page
    home: {
      init: function () {
        // JavaScript to be fired on the home page
      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
      },
    },
    // About us page, note the change from about-us to about_us.
    gracias: {
      init: function () {
      },
    },
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = funcname === undefined ? "init" : funcname;
      fire = func !== "";
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === "function";

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire("common");

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, "_").split(/\s+/), function (
        i,
        classnm
      ) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, "finalize");
      });

      // Fire common finalize JS
      UTIL.fire("common", "finalize");
    },
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);
})(jQuery); // Fully reference jQuery after this point.
